import * as React from "react"
import styled from "styled-components"

import { Link } from "gatsby";
import BadgeAlt from "../components/badgeAlt"
import SEO from "../components/seo"
import Footer from "../components/footer"

const pageStyles = {
  position: "relative",
  height: "100vh",
  backgroundColor: "#3F3F3F",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
  backgroundSize: "cover",
  backgroundPosition: "center"
}

const Frame = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 2rem;
`

const FrameWrapper = styled.div`
  position: relative;
  background: #FFF;
  margin: 0 auto;
  padding: 3rem 3rem 8rem 3rem;
  max-width: 60rem;
  @media (min-width: 768px) {
    padding: 4.5rem 5rem;
  }
`

const FrameBadge = styled.div`
  position: absolute;
  display: none;
  @media (min-width: 768px) {
    display: block;
    top: -3rem;
    left: 3rem;
  }
  @media (min-width: 1200px) {
    top: 4rem;
    left: -3rem;
  }
`

const FrameButtons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  right: -1rem;
  bottom: -4rem;
  @media (min-width: 768px) {
    flex-direction: row;
    bottom: -2rem;
    left: 5rem;
  }
`

const FrameButtonsItem = styled.div`
  margin-bottom: 1rem;
  @media (min-width: 768px) {
    margin-bottom: 0;
    margin-right: 1.5rem;
  }
`

const frameTitleStyles = {
  marginBottom: "1.3rem",
  color: "#3F3F3F",
  fontFamily: "Montserrat",
  fontWeight: "800",
  fontStyle: "normal",
  fontSize: "0.8rem",
  textTransform: "uppercase",
  letterSpacing: "6px"
}

const frameTextStyles = {
  marginBottom: "1.2rem",
  fontSize: "2.6rem",
  lineHeight: "2.4rem",
  color: "#3F3F3F",
  fontFamily: "Source Serif Pro",  
  fontWeight: "300"
}

const frameBodyStyles = {
  fontSize: "1.2rem",
  lineHeight: "2.2rem",
  color: "#3F3F3F",
  fontFamily: "Montserrat",
  fontWeight: "400"
}

const buttonStyles = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "1.5rem",
  color: "#FFF",
  border: "0",
  background: "#5D21D0",
  cursor: "pointer",
  fontFamily: "Montserrat",
  fontWeight: "800",
  fontSize: "0.8rem",
  fontStyle: "normal",
  textTransform: "uppercase",
  letterSpacing: "6px",
  boxShadow: "0px 54px 80px rgba(0, 0, 0, 0.08), 0px 22.5599px 33.4221px rgba(0, 0, 0, 0.0575083), 0px 12.0616px 17.869px rgba(0, 0, 0, 0.0476886), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.04), 0px 3.59106px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 1.49432px 2.21381px rgba(0, 0, 0, 0.0224916)",
  textDecoration: "none"
}

const buttonTextStyles = {
  marginRight: "1.2rem"
}

const NotFoundPage = () => {
  return (
    <main>
      <SEO title="404"></SEO>
        <div style={Object.assign({}, 
            pageStyles, 
            { backgroundImage: `url(../../not-found.png)` })}>
          <Frame>
            <FrameWrapper>
              <FrameBadge>
                <BadgeAlt />
              </FrameBadge>
              <p style={frameTitleStyles}>4, 8, 15, 16, 23, 42 and</p>
              <p style={frameTextStyles}>404</p>
              <p style={frameBodyStyles}>Come on, this site is literally one page and you ended up here?</p>
              <FrameButtons>
                <FrameButtonsItem>
                  <Link to="/" style={buttonStyles}>
                    <span style={buttonTextStyles}>Let's get you home</span>
                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.03 13.03a.75.75 0 000-1.06l-4.773-4.773a.75.75 0 00-1.06 1.06l4.242 4.243-4.242 4.243a.75.75 0 001.06 1.06l4.773-4.773zm-11.53.22h11v-1.5h-11v1.5z" fill="#fff"/><circle cx="13" cy="13" r="12.25" stroke="#fff" stroke-width="1.5"/></svg>
                  </Link>
                </FrameButtonsItem>
              </FrameButtons>
            </FrameWrapper>
          </Frame>
        </div>
      <Footer />
    </main>
  )
}

export default NotFoundPage
