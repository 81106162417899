import * as React from "react"

const badgeOne = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  bottom: "10%",
  height: "100px",
  width: "100px",
  background: "#9EF1C8",
  borderRadius: "100px",
  boxShadow: "0px 54px 80px rgba(0, 0, 0, 0.08), 0px 22.5599px 33.4221px rgba(0, 0, 0, 0.0575083), 0px 12.0616px 17.869px rgba(0, 0, 0, 0.0476886), 0px 6.76164px 10.0172px rgba(0, 0, 0, 0.04), 0px 3.59106px 5.32008px rgba(0, 0, 0, 0.0323114), 0px 1.49432px 2.21381px rgba(0, 0, 0, 0.0224916)"
}

const BadgeAlt = () => (
  <div style={badgeOne}>
    <svg width="99" height="99" viewBox="0 0 99 99" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="49.5" cy="49.5" r="49.5" fill="#9EF1C8"/><path d="M50 73.81c13.15 0 23.81-10.66 23.81-23.81 0-13.15-10.66-23.81-23.81-23.81-13.15 0-23.81 10.66-23.81 23.81 0 13.15 10.66 23.81 23.81 23.81z" stroke="#3F3F3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/><path d="M40.714 45.119l7.381 1.19-5 3.453M68.333 45.119l-7.38 1.19 5 3.453M63.452 59.762c-5.833-5.833-15.357-5.833-21.19 0M56.19 44.881v4.524H52.5" stroke="#3F3F3F" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/></svg>
  </div>
)

export default BadgeAlt
